import * as React from "react";
import styled from "@emotion/styled";
import * as Moment from "moment"
import {useEffect, useMemo, useState} from "react";
import ContactModal from "./ContactModal";
import auth from "../../../utils/auth";
import {useDispatch, useSelector} from "react-redux";
import confirmModal from "../../modals/confirmModal";
import warningModal from "../../modals/warningModal";

const Container = styled.div`
  padding: 32px;
  margin: 0 auto;
  width: ${(props: any) => props.width ?? 1000}px;
  background: #ffffff;
  border-radius: 8px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const HeaderBody = styled.div`
  flex: 1;
  display: flex;
  gap: 20px;
`

const Avatar = styled.img`
  margin: 0;
  padding: 0;
  height: 94px;
  width: 94px;
  vertical-align: bottom;
  border-radius: 47px;
`

const HeaderInfo = styled.div`
`

const HeaderContent = styled.div`
`

const Name = styled.div`
  margin: 0;
  line-height: 22px;
  display: flex;
  & span {
    font-size: 16px;
    font-family: PingFang PingFang SC-Bold, PingFang SC, sans-serif;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const Certificate = styled.img`
  margin: 0 0 0 6px;
  height: 20px;
  width: 58px;
  vertical-align: bottom;
`

const Title = styled.div`
  margin-top: 12px;
  line-height: 22px;
  font-size: 14px;
  font-family: PingFang PingFang SC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
`

const Tags = styled.div`
  margin-top: 12px;
  display: flex;
  gap: 6px;
`

const Tag = styled.div`
  padding: 0 12px;
  line-height: 26px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
`

const HeaderTools = styled.div`
`

const Share = styled.a`
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 14px;
  font-family: PingFang PingFang SC-Medium, PingFang SC, sans-serif;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
  line-height: 22px;
  text-decoration: none;
`

const ShareIcon = styled.div`
  width: 16px;
  height: 16px;
  background-image: url("/images/icons/share.png");
  background-repeat: no-repeat;
  background-size: 100%;
`

const Body = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 50px;
`

const Infos = styled.div`
  line-height: 22px;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  column-gap: 32px;
  row-gap: 16px;
`

const Info = styled.div`
  min-width: 200px;
  display: flex;
`

const InfoLabel = styled.div`
  margin-right: 12px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  &:after {
    content: ":"
  }
`

const InfoContents = styled.div`
  display: flex;
  column-gap: 38px;
`

const InfoContent = styled.div`
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: #000000;
`

const Actions = styled.div`
  display: flex;
  gap: 8px;
  align-items: end;
`

const ButtonPrimary = styled.button`
  padding: 0 23px;
  height: 40px;
  line-height: 38px;
  border: 1px solid #000000;
  border-radius: 4px;
  background-color: #000000;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC, sans-serif;
  font-weight: 500;
  color: #ffffff;
  &:hover {
    cursor: pointer;
  }
`

const ButtonOutline = styled.button`
  padding: 0 23px;
  height: 40px;
  line-height: 38px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC, sans-serif;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  &:hover {
    cursor: pointer;
  }
`

const TeamInfo = (props) => {
  const {
    id,
    audited = false,
    avatar, name = "", title="",
    weChat, email,
    isShowContact = false,
    tags = [],
    remoteWage, siteWage,
    resume,
    ...containerProps
  } = props
  const {isWorkDay, isSaturday, isSunday, isSite, isRemote,} = resume
  auth.authorize()
  const {account} = useSelector((s: any) => s)

  const [showContact, setShowContact] = useState(false)

  const [
    workDayStartTime, workDayEndTime,
    saturdayStartTime, saturdayEndTime,
    sundayStartTime, sundayEndTime,
  ] = useMemo(() => {

    return ['workDayStartTime', 'workDayEndTime',
      'saturdayStartTime', 'saturdayEndTime',
      'sundayStartTime', 'sundayEndTime'].map(key => {
        return Moment(`${resume[key]}`.padStart(4, '0'), 'HHmm').format('HH:mm')
    })
  }, [props.resume])

  return (<>
    <Container {...containerProps}>
      <Header>
        <HeaderBody>
          {/*<Avatar src={require("../../../assets/pages/organize/avatar.png").default}/>*/}
          <Avatar src={avatar}/>
          <HeaderInfo>
            <HeaderContent>
              <Name><span>{name}</span> {audited && <Certificate src={"/images/icons/cert.png"}/>}</Name>
              <Title>{title}</Title>
            </HeaderContent>
            <Tags>
              {tags.map(tag => (
                <Tag key={tag}>{tag}</Tag>
              ))}
            </Tags>
          </HeaderInfo>
        </HeaderBody>
        <HeaderTools>
          {/*<Share href="#">*/}
          {/*  <ShareIcon/> 分享*/}
          {/*</Share>*/}
        </HeaderTools>
      </Header>
      <Body>
        <Infos>
          <Info>
            <InfoLabel>远程工作</InfoLabel>
            <InfoContent>{isRemote ? `${parseFloat(remoteWage).toFixed(0)}元/人天` : '不接受'}</InfoContent>
          </Info>
          <Info>
            <InfoLabel>驻场工作</InfoLabel>
            <InfoContent>{isSite ? `${parseFloat(siteWage).toFixed(0)}元/人天` : '不接受'}</InfoContent>
          </Info>
          <Info>
            <InfoLabel>工作时间</InfoLabel>
            <InfoContents>
              <InfoContent>工作日: {isWorkDay ? `${workDayStartTime}至${workDayEndTime}` : '休息'}</InfoContent>
              <InfoContent>星期六: {isSaturday ? `${saturdayStartTime}至${saturdayEndTime}` : '休息'}</InfoContent>
              <InfoContent>星期天: {isSunday ? `${sundayStartTime}至${sundayEndTime}` : '休息'}</InfoContent>
            </InfoContents>
          </Info>
        </Infos>
        <Actions>
          <a href={`/project?id=${resume.id}`} target='_blank'><ButtonPrimary>立即预约</ButtonPrimary></a>
          <ButtonOutline
            onClick={() => {
              if (account.resumeSetting?.auditStatus === 'auditSuccess') {
                if (isShowContact) {
                  setShowContact(true);
                } else {
                  warningModal({
                    content: '用户已隐藏联系方式'
                  })
                }
              } else {
                confirmModal({
                  title: '查看权限',
                  content: '请先完成个人履历认证后再操作',
                  okText: '去认证',
                  onOk: () => {
                    window.open("/personal/resume")
                  }
                })
              }
            }}
          >联系方式</ButtonOutline>
        </Actions>
      </Body>
    </Container>
    <ContactModal
      open={showContact}
      id={id}
      onCancel={() => setShowContact(false)}
      {...{avatar, name, title, weChat, email, audited}}
    />
  </>)
}

export default TeamInfo
