import * as React from "react";
import TeamCard from "../../components/pages/team/TeamCard";
import TeamInfo from "../../components/pages/team/TeamInfo";
import TeamChapter from "../../components/pages/team/TeamChapter";
import TeamSkills from "../../components/pages/team/TeamSkills";
import TeamCase from "../../components/pages/team/TeamCase";
import PageWrapper from "../../components/pages/PageWrapper";
import * as queryString from "query-string";
import {useEffect, useMemo, useState} from "react";
import {navigate} from "gatsby";
import jsonRequest from "../../utils/request/jsonRequest";
import {Col, message, Row} from "antd";
import workTypes from "../../utils/options/workTypes";
import workStatus from "../../utils/options/workStatus";
import * as Moment from "moment"
import auth from "../../utils/auth";
import Seo from '../../components/seo'
import styled from "@emotion/styled";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {useSelector} from "react-redux";
import TeamComment from "../../components/pages/team/TeamComment";

const bodyWidth = 1000;

const UnreadableWarning = styled.div`
  margin: 0 auto;
  padding: 100px 0;
  width: 438px;
  text-align: center;
  border-radius: 8px;
  background-color: #ffffff;
  .icon {
    color: rgb(255, 114, 0);
    font-size: 36px;
  }
  .content {
    margin-top: 20px;
    font-size: 16px;
    font-family: PingFang SC-Bold, PingFang SC,sans-serif;
    font-weight: bold;
    color: rgba(0,0,0,0.8);
    line-height: 24px;
  }
`

const Team = ({location}) => {
  auth.authorize()
  const {account} = useSelector((selector: any) => selector)
  const params = useMemo(() => {
    return queryString.parse(location.search)
  }, [location])
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [readable, setReadable] = useState(true)

  const load = () => {
    if (loading || !params?.id) {
      return;
    }
    setLoading(true)
    jsonRequest(`/resume/${params.id}`).then(rsp => {
      if (rsp.success) {
        setReadable(true)
        setData(rsp.data.data)
        if (!rsp.data.data.resumeSetting.resumeUpdatedAt && rsp.data.data.id === account.id) {
          message.warning('请完善个人履历')
        }
      } else {
        if (rsp.data.code === 1001) {
          // setReadable(false)
          message.warning('履历已隐藏')
        } else {
          message.error(rsp.message);
        }
    }
    }).catch(console.error).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    if (!params.id) {
      navigate("/organize")
    } else if (account) {
      load()
    }
  }, [params, account])
  return (<>
    <PageWrapper loading={loading}>
      {!readable && (
        <UnreadableWarning>
          <div className='icon'>
            <ExclamationCircleFilled />
          </div>
          <div className="content">
            该详情已隐藏
          </div>
        </UnreadableWarning>
      )}
      {data?.resumeSetting.resumeUpdatedAt && (<>
        <TeamInfo
          id={data.id}
          avatar={data.avatar}
          name={data.nickname}
          title={data.resume.title}
          isShowContact={data.resumeSetting?.isShowContact}
          tags={[...(data.resume.region.acronym === 'CN' ? (data.city ? [data.city] : []) : [data.resume.region?.name]), `${data.resume.workYear}年经验`, workStatus[data.resume.workStatus] ?? data.resume.workStatus, workTypes[data.resume.workType] ?? data.resume.workType, ...(data.resume.job?.name ? [data.resume.job.name] : [])]}
          remoteWage={data.resume.remoteWage}
          siteWage={data.resume.siteWage}
          weChat={data.weChat}
          email={data.email}
          width={bodyWidth}
          resume={data.resume}
          audited={data.resumeSetting?.auditStatus == 'auditSuccess'}
        />
        <TeamCard title='个人简介' width={bodyWidth}>
          {data.introduction?.content ?? ''}
        </TeamCard>
        {data.educations?.length > 0 && (
          <TeamCard title='学习经历' width={bodyWidth} flex gap={40}>
            {data.educations?.map(item => (
              <TeamChapter
                key={item.id}
                title={item.school}
                append={`${item.major}·${item.degree}`}
                desc={`${Moment(item.startTime).format("YYYY-MM-DD")} - ${item.endTime ? Moment(item.endTime).format("YYYY-MM-DD") : '至今'}`}
              >
                {item.content ?? ''}
              </TeamChapter>
            ))}

          </TeamCard>
        )}
        {data.experiences?.length > 0 && (
          <TeamCard title='工作经历' width={bodyWidth} flex gap={40}>
            {data.experiences?.map(item => (
              <TeamChapter
                key={item.id}
                title={item.company}
                append={item.job}
                desc={`${Moment(item.startTime).format("YYYY-MM-DD")} - ${item.endTime ? Moment(item.endTime).format("YYYY-MM-DD") : '至今'}`}
              >
                {item.content}
              </TeamChapter>
            ))}
          </TeamCard>
        )}
        {data.skills && (
          <TeamCard title='专业技能' width={bodyWidth}>
            <TeamSkills
              data={data.skills}
            />
          </TeamCard>
        )}
        {
          data.cases && (
            <TeamCard
              title='项目案例'
              width={bodyWidth}
              flex gap={33}
              direction='row'
              contentProps={{
                style: {
                  flexWrap: 'wrap'
                }
              }}
            >
              {data.cases?.map((c) => (
                <TeamCase
                  key={c.id}
                  width="calc(33.3% - 22px)"
                  {...{...c, cover: c.coverUrl}}
                  href={`/team/case?id=${c.id}`}
                />
              ))}
            </TeamCard>
          )
        }
        <div
          style={{
            width: bodyWidth,
            margin: '0 auto',
          }}
        >
          <Row gutter={16}>
            {data.comments?.map(comment => (
              <Col span={12} key={comment.id}>
                <TeamCard>
                  <TeamComment data={comment}/>
                </TeamCard>
              </Col>
            ))}
          </Row>
        </div>
      </>)}
    </PageWrapper>
  </>)
}

export const Head = () => <Seo title='发现团队' />

export default Team;
