import * as React from "react";
import {Button, Divider, Modal, ModalProps} from "antd";
import styled from "@emotion/styled";
import {useEffect, useMemo, useState} from "react";
import jsonRequest from "../../../utils/request/jsonRequest";
import {useRequest} from "ahooks";
import {ExclamationCircleFilled} from "@ant-design/icons";

const Header = styled.div`
  display: flex;
  //align-items: center;
`

const Avatar = styled.img`
  margin: 0;
  height: 48px;
  width: 48px;
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.12);
`

const HeaderContent = styled.div`
  margin-left: 16px;
  max-width: calc(100% - 80px);
`

const Name = styled.div`
  margin: 0;
  line-height: 22px;
  font-size: 16px;
  font-family: PingFang PingFang SC-Bold, PingFang SC, sans-serif;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  
  .nameText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const Certificate = styled.img`
  margin: 0 0 0 6px;
  height: 20px;
  width: 58px;
  vertical-align: top;
`

const Title = styled.div`
  margin-top: 4px;
  line-height: 22px;
  font-size: 14px;
  font-family: PingFang PingFang SC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
`

const ErrorMessage = styled.div`
  height: 24px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC, sans-serif;
  font-weight: bold;
  color: rgba(0,0,0,0.8);
  line-height: 24px;
`

const ContactTitle = styled.div`
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC, sans-serif;
  font-weight: bold;
  color: rgba(0,0,0,0.8);
  line-height: 22px;
`

const List = styled.ul`
  margin: 16px 0 0;
  padding: 0;
`

const Item = styled.li`
  margin-bottom: 8px;
  height: 22px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.8);
  line-height: 22px;
  list-style: none;
`

const Label = styled.span`
  &:after {
    content: ': ';
    display: inline;
  }
`

export type ContactModalProps = {
  id?: string | number;
  avatar?: string;
  name?: string;
  title?: string;
  weChat?: string;
  email?: string;
  audited?: boolean;
} & ModalProps

export default (props: ContactModalProps) => {
  const {
    open,
    id, avatar = '', name = '', title = '', weChat, email, audited = false, width = 480,
    onCancel = () => {},
    ...modalProps
  } = props

  const [showModal, setShowModal] = useState(false);

  const {data, run, loading}: any = useRequest(() => {
    return jsonRequest(`/resume/${id}/react_contact`, {
      method: 'POST',
    }).catch(console.error)
  }, {manual: true})

  useEffect(() => {
    if (data && !loading) {
      setShowModal(true)
    }
  }, [data, loading]);

  useEffect(() => {
    if (!!id && open) {
      run();
    } else {
      setShowModal(false)
    }
  }, [open])

  return (
    <Modal
      {...{
        footer: [
          <Button key='cancelBtn' type="primary" onClick={onCancel}>关闭</Button>
        ],
        onCancel,
        width,
        open: showModal,
        ...modalProps
      }}
      bodyStyle={{paddingBottom: 16}}
    >
      {
        data?.success && (<>
          <Header>
            {
              <Avatar src={avatar}/>
            }
            <HeaderContent>
              <Name><div className='nameText'>{name}</div>{audited && (<Certificate src={"/images/icons/cert.png"}/>)}</Name>
              <Title>{title}</Title>
            </HeaderContent>

          </Header>
          <Divider style={{margin: '16px 0'}} />
          <ContactTitle>联系方式</ContactTitle>
          <List>
            <Item>
              <Label>邮箱</Label>{email ?? '-'}
            </Item>
            <Item>
              <Label>微信</Label>{weChat ?? '-'}
            </Item>
          </List>
        </>) || (<>
          <ContactTitle><ExclamationCircleFilled style={{fontSize: 24, verticalAlign: 'text-bottom', color: 'rgb(251,92,9)'}} />&nbsp;&nbsp;您的免费查看联系方式次数已用完</ContactTitle>
        </>)
      }
    </Modal>
  )
}
